
.logo {
  height: 130px;
  width: 170px;
}

.space {
  line-height: 1.6;
}

.img-container {
  text-align: center;
  display: block;
}

.headings {
  text-align: center;
}

.Container {
  width: 80%;
  margin: 0 auto;
}

h6 {
  padding: 5px;
  margin: 0;
}

.customers {
  border-collapse: collapse;
  width: 100%;
}

.customers td,
.customers th {
  padding: 8px;
}

.customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

.customers th {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}
